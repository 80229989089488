* {
  box-sizing: border-box;
  padding: 0;
}

.image_class {
  border-radius: 25px;
  margin-top: 5px;

}

:root {
  --side-width: 271px;
}

.APPBar {
  background-color: #ffffff;
  border-bottom: 1px solid rgb(209, 209, 209);
  box-shadow: 0px 0px 0px white;
  margin-bottom: 25px;
  margin-left: var(--side-width);
  width: calc(100% - var(--side-width));
}

.gap_class {
  display: flex;
  gap: 20px;
  justify-content: end;
  padding: 15px;
}

@media (max-width:500px) {
  .gap_class {
    margin-left: var(--side-width);
    gap: 0px;
  }
}

.Uplaod_btn {
  background-color: #22e4db !important;
  width: 150px;
  height: 50px;
  border-radius: 30px;
}