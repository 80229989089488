.btn-link {
  background: transparent;
  border: 0;
  outline: 0;
  padding: 2px 4px;
}

.btn-link svg {
  height: 18px;
  width: 18px;
}

.btn-link:hover svg,
.btn-link:hover path {
  fill: #FD7E9C;
}