.login_btn {
	width: 50%;
	height: 100%;
	background-color: #22e4db;
	display: flex;
	justify-content: center;
	margin-top: 40px;
	border-radius: 50px;
	margin-left: 80px;
}

.Image_class {
	width: 500px;
}

@media (max-width:768px) {
	.Image_class {
		display: none;
	}
}

@media (max-width:960px) {
	.Image_class {
		width: 300px;
	}
}

.login_link_class {
	color: white;
	display: flex;
	justify-content: center;
	padding: 16px;
	cursor: pointer;
	text-decoration: none;
}