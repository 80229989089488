.run_class_btn {
    margin-top: 20px;
    padding-left: 35px;
    padding-right: 35px;
}

.run_class_btn:hover {}

.main_label_class {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.MuiSwitch-colorSecondary.Mui-checked {
    color: #22E4DB !important;
}

.MuiSwitch-colorSecondary.Mui-checked+.MuiSwitch-track {
    background-color: #22E4DB !important;
}