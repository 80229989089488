.list-nav {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}

.list-nav a {
  text-decoration: none;
}

.list-nav .MuiListItemButton-root {
  display: flex;
  align-items: center;
}

.list-nav .MuiListItemIcon-root {
  color: white;
}

.list-nav .MuiListItemText-primary {
  color: white;
}

.list-nav .MuiCollapse-container {
  margin-left: 16px;
}

.list-nav .MuiListItemButton-root:hover,
.list-nav .MuiListItemButton-root:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

.list-nav .MuiListItemButton-root.Mui-selected,
.list-nav .MuiListItemButton-root.Mui-selected:hover,
.list-nav .MuiListItemButton-root.Mui-selected:focus {
  background-color: rgba(255, 255, 255, 0.2);
}

.list-nav .white-icon {
  color: white;
}

.list-nav .list-item {
  color: #fff;
  padding: 15px 15px;
  cursor: pointer;
  user-select: none;
}

.list-nav .list-item .list-item {
  /* margin-left: 25px; */
}

.list-nav .list-item .circle {
  margin-right: 10px;
  color: transparent;
  line-height: 17px;
  font-size: 10px;
  text-align: center;
  width: 20px;
  height: 20px;
  aspect-ratio: 1/1;
  display: inline-block;
  padding: 0px;
  border: 2px solid #fff;
  border-radius: 50%;
  user-select: none;
}

.list-nav .list-item.active .circle {
  margin-right: 10px;
  color: #fff;
  border: 2px solid #fff;
}

.dropdown.list-item {
  /* background-color: #22e4da3c !important; */
}

li .list-item {
  background-color: #22e4da3c !important;
}

.list-nav .item-1 {
  padding-left: 5px;
}

.list-nav .item-2 {
  padding-left: 15px !important;
}

.list-nav .item-3 {
  padding-left: 15px !important;
}

.list-nav .item-4 {
  padding-left: 45px !important;
}

.list-nav .dropdown-1 {}

.list-nav .dropdown-2 {
  background-color: #22e4da9a !important;
}

.list-nav .dropdown-3 {
  background-color: #1B1429 !important;
}

.list-nav .dropdown-4 {}

.side-icon {
  width: 24px;
  height: 24px;
  aspect-ratio: 1/1;
  object-fit: contain;
}

.bottom-nav {
  justify-content: space-evenly;
  margin-bottom: 15px;
}

.bottom-nav .btn-action {
  color: white;
  display: flex;
  align-items: center;
  gap: 10px;
  text-transform: capitalize;
  width: "45%"
}

.bottom-nav .btn-action .icon {
  width: 22px;
  height: 22px;
  aspect-ratio: 1/1;
  object-fit: contain;
}

.policy_class {
  min-width: 30px !important;
}

.MuiPaper-elevation0 {
  /* overflow-y: hidden; */
}

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px gray;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
}