@import url(https://fonts.googleapis.com/css?family=Poppins:100,100italic,200,200italic,300,300italic,regular,italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic);
*, *:before, *:after {
    font-family: 'Poppins', sans-serif;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

.MuiIconButton-label svg polygon{
    fill: rgb(93, 184, 1);

}
.swal2-confirm {
  background-color: #22e4db !important;
  border: none;
  width: 100px;
  padding: 15px;
  border-radius: 25px !important;
}

.login_btn {
	width: 50%;
	height: 100%;
	background-color: #22e4db;
	display: flex;
	justify-content: center;
	margin-top: 40px;
	border-radius: 50px;
	margin-left: 80px;
}

.Image_class {
	width: 500px;
}

.paper_class {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

@media (max-width:768px) {
	.Image_class {
		display: none;
	}
}

@media (max-width:960px) {
	.Image_class {
		width: 300px;
	}
}

.login_link_class {
	color: white;
	display: flex;
	justify-content: center;
	padding: 16px;
	cursor: pointer;
	text-decoration: none;
}
.login_btn {
	width: 50%;
	height: 100%;
	background-color: #22e4db;
	display: flex;
	justify-content: center;
	margin-top: 40px;
	border-radius: 50px;
	margin-left: 80px;
}

.Image_class {
	width: 500px;
}

@media (max-width:768px) {
	.Image_class {
		display: none;
	}
}

@media (max-width:960px) {
	.Image_class {
		width: 300px;
	}
}

.login_link_class {
	color: white;
	display: flex;
	justify-content: center;
	padding: 16px;
	cursor: pointer;
	text-decoration: none;
}
.login_btn {
	width: 50%;
	height: 100%;
	background-color: #22e4db;
	display: flex;
	justify-content: center;
	margin-top: 40px;
	border-radius: 50px;
	margin-left: 80px;
}

.Image_class {
	width: 500px;
}

@media (max-width:768px) {
	.Image_class {
		display: none;
	}
}

@media (max-width:960px) {
	.Image_class {
		width: 300px;
	}
}

.login_link_class {
	color: white;
	display: flex;
	justify-content: center;
	padding: 16px;
	cursor: pointer;
	text-decoration: none;
}

.css-1jzavp6-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #C7C7C7 !important;
    border-width: 2px;
}

.css-1y73k9m-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #C7C7C7 !important;
    border-width: 2px;
}
.run_class_btn {
    margin-top: 20px;
    padding-left: 35px;
    padding-right: 35px;
}

.run_class_btn:hover {}

.main_label_class {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.MuiSwitch-colorSecondary.Mui-checked {
    color: #22E4DB !important;
}

.MuiSwitch-colorSecondary.Mui-checked+.MuiSwitch-track {
    background-color: #22E4DB !important;
}
.btn-link {
  background: transparent;
  border: 0;
  outline: 0;
  padding: 2px 4px;
}

.btn-link svg {
  height: 18px;
  width: 18px;
}

.btn-link:hover svg,
.btn-link:hover path {
  fill: #FD7E9C;
}
* {
  box-sizing: border-box;
  padding: 0;
}

.image_class {
  border-radius: 25px;
  margin-top: 5px;

}

:root {
  --side-width: 271px;
}

.APPBar {
  background-color: #ffffff;
  border-bottom: 1px solid rgb(209, 209, 209);
  box-shadow: 0px 0px 0px white;
  margin-bottom: 25px;
  margin-left: 271px;
  margin-left: var(--side-width);
  width: calc(100% - 271px);
  width: calc(100% - var(--side-width));
}

.gap_class {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  justify-content: end;
  padding: 15px;
}

@media (max-width:500px) {
  .gap_class {
    margin-left: 271px;
    margin-left: var(--side-width);
    grid-gap: 0px;
    gap: 0px;
  }
}

.Uplaod_btn {
  background-color: #22e4db !important;
  width: 150px;
  height: 50px;
  border-radius: 30px;
}
.list-nav {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}

.list-nav a {
  text-decoration: none;
}

.list-nav .MuiListItemButton-root {
  display: flex;
  align-items: center;
}

.list-nav .MuiListItemIcon-root {
  color: white;
}

.list-nav .MuiListItemText-primary {
  color: white;
}

.list-nav .MuiCollapse-container {
  margin-left: 16px;
}

.list-nav .MuiListItemButton-root:hover,
.list-nav .MuiListItemButton-root:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

.list-nav .MuiListItemButton-root.Mui-selected,
.list-nav .MuiListItemButton-root.Mui-selected:hover,
.list-nav .MuiListItemButton-root.Mui-selected:focus {
  background-color: rgba(255, 255, 255, 0.2);
}

.list-nav .white-icon {
  color: white;
}

.list-nav .list-item {
  color: #fff;
  padding: 15px 15px;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}

.list-nav .list-item .list-item {
  /* margin-left: 25px; */
}

.list-nav .list-item .circle {
  margin-right: 10px;
  color: transparent;
  line-height: 17px;
  font-size: 10px;
  text-align: center;
  width: 20px;
  height: 20px;
  aspect-ratio: 1/1;
  display: inline-block;
  padding: 0px;
  border: 2px solid #fff;
  border-radius: 50%;
  -webkit-user-select: none;
          user-select: none;
}

.list-nav .list-item.active .circle {
  margin-right: 10px;
  color: #fff;
  border: 2px solid #fff;
}

.dropdown.list-item {
  /* background-color: #22e4da3c !important; */
}

li .list-item {
  background-color: #22e4da3c !important;
}

.list-nav .item-1 {
  padding-left: 5px;
}

.list-nav .item-2 {
  padding-left: 15px !important;
}

.list-nav .item-3 {
  padding-left: 15px !important;
}

.list-nav .item-4 {
  padding-left: 45px !important;
}

.list-nav .dropdown-1 {}

.list-nav .dropdown-2 {
  background-color: #22e4da9a !important;
}

.list-nav .dropdown-3 {
  background-color: #1B1429 !important;
}

.list-nav .dropdown-4 {}

.side-icon {
  width: 24px;
  height: 24px;
  aspect-ratio: 1/1;
  object-fit: contain;
}

.bottom-nav {
  justify-content: space-evenly;
  margin-bottom: 15px;
}

.bottom-nav .btn-action {
  color: white;
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  text-transform: capitalize;
  width: "45%"
}

.bottom-nav .btn-action .icon {
  width: 22px;
  height: 22px;
  aspect-ratio: 1/1;
  object-fit: contain;
}

.policy_class {
  min-width: 30px !important;
}

.MuiPaper-elevation0 {
  /* overflow-y: hidden; */
}

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px gray;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
}
* {
    margin: 0;
    padding: 0;
    /* box-sizing: border-box */
}



a:focus {
    outline: none !important
}

a:hover {
    text-decoration: none
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0
}

p {
    margin: 0
}

ul,
li {
    margin: 0;
    list-style-type: none
}

input {
    display: block;
    outline: none;
    border: none !important
}

textarea {
    display: block;
    outline: none
}

textarea:focus,
input:focus {
    border-color: transparent !important
}

button {
    outline: none !important;
    border: none;
    background: 0 0
}

button:hover {
    cursor: pointer
}

iframe {
    border: none !important
}

.js-pscroll {
    position: relative;
    overflow: hidden
}

.table100 .ps__rail-y {
    width: 9px;
    background-color: transparent;
    opacity: 1 !important;
    right: 5px
}

.table100 .ps__rail-y::before {
    content: "";
    display: block;
    position: absolute;
    background-color: #ebebeb;
    border-radius: 5px;
    width: 100%;
    height: calc(100% - 30px);
    left: 0;
    top: 15px
}

.table100 .ps__rail-y .ps__thumb-y {
    width: 100%;
    right: 0;
    background-color: transparent;
    opacity: 1 !important
}

.table100 .ps__rail-y .ps__thumb-y::before {
    content: "";
    display: block;
    position: absolute;
    background-color: #ccc;
    border-radius: 5px;
    width: 100%;
    height: calc(100% - 30px);
    left: 0;
    top: 15px
}

.limiter {
    width: 1366px;
    margin: 0 auto
}

.container-table100 {
    width: 100%;
    min-height: 100vh;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 33px 30px
}

.wrap-table100 {
    width: '100%'
}

.table100 {
    background-color: #fff
}

table {
    width: 100%
}

th,
td {
    font-weight: unset;
    padding-right: 10px
}

.column1 {
    width: 20%;
}

.column2 {
    width: 15%
}

.column3 {
    width: 20%
}

.column4 {
    width: 20%
}

.column5 {
    width: 20%
}

.table100-head th {
    padding-top: 18px;
    padding-bottom: 18px
}

.table100-body td {
    padding-top: 16px;
    padding-bottom: 16px
}

.table100 {
    position: relative;
    padding-top: 60px
}

.table100-head {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0
}

.table100-body {
    max-height: 380px;
    /* max-height: '100%'; */
    overflow: auto
}

.table100.ver1 th {
    font-size: 15px;
    font-weight: bold;
    color: #787E90;
    text-align: start;
    line-height: 1;
    padding-left: 5px;
    /* background-color: #a1a1a1 */
}

.table100.ver1 td {
    font-size: 15px;
    color: rgb(0, 0, 0);
    line-height: 1.1;
    padding-left: 5px;
}

.table100.ver1 .table100-body tr:nth-child(even) {
    background-color: #f5f5f5;
}

.table100.ver1 {
    border-radius: 10px;
    overflow: hidden;
    /* box-shadow: 0 0 40px 0 rgba(0, 0, 0, .15); */
    /* -moz-box-shadow: 0 0 40px 0 rgba(0, 0, 0, .15); */
    /* -webkit-box-shadow: 0 0 40px 0 rgba(0, 0, 0, .15); */
    /* -o-box-shadow: 0 0 40px 0 rgba(0, 0, 0, .15); */
    /* -ms-box-shadow: 0 0 40px 0 rgba(0, 0, 0, .15) */
}

.table100.ver1 .ps__rail-y {
    right: 5px
}

.table100.ver1 .ps__rail-y::before {
    background-color: #ebebeb
}

.table100.ver1 .ps__rail-y .ps__thumb-y::before {
    background-color: #ccc
}

.table100.ver2 .table100-head {
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .1);
    -moz-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .1);
    -webkit-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .1);
    -o-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .1);
    -ms-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .1)
}

.table100.ver2 th {
    font-family: Lato-Bold;
    font-size: 18px;
    color: #fa4251;
    line-height: 1.4;
    background-color: transparent
}

.table100.ver2 td {
    font-family: Lato-Regular;
    font-size: 15px;
    color: gray;
    line-height: 1.4
}

.table100.ver2 .table100-body tr {
    border-bottom: 1px solid #f2f2f2
}

.table100.ver2 {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, .15);
    -moz-box-shadow: 0 0 40px 0 rgba(0, 0, 0, .15);
    -webkit-box-shadow: 0 0 40px 0 rgba(0, 0, 0, .15);
    -o-box-shadow: 0 0 40px 0 rgba(0, 0, 0, .15);
    -ms-box-shadow: 0 0 40px 0 rgba(0, 0, 0, .15)
}

.table100.ver2 .ps__rail-y {
    right: 5px
}

.table100.ver2 .ps__rail-y::before {
    background-color: #ebebeb
}

.table100.ver2 .ps__rail-y .ps__thumb-y::before {
    background-color: #ccc
}

.table100.ver3 {
    background-color: #393939
}

.table100.ver3 th {
    font-family: Lato-Bold;
    font-size: 15px;
    color: #00ad5f;
    line-height: 1.4;
    text-transform: uppercase;
    background-color: #393939
}

.table100.ver3 td {
    font-family: Lato-Regular;
    font-size: 15px;
    color: gray;
    line-height: 1.4;
    background-color: #222
}

.table100.ver3 {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, .15);
    -moz-box-shadow: 0 0 40px 0 rgba(0, 0, 0, .15);
    -webkit-box-shadow: 0 0 40px 0 rgba(0, 0, 0, .15);
    -o-box-shadow: 0 0 40px 0 rgba(0, 0, 0, .15);
    -ms-box-shadow: 0 0 40px 0 rgba(0, 0, 0, .15)
}

.table100.ver3 .ps__rail-y {
    right: 5px
}

.table100.ver3 .ps__rail-y::before {
    background-color: #4e4e4e
}

.table100.ver3 .ps__rail-y .ps__thumb-y::before {
    background-color: #00ad5f
}

.table100.ver4 {
    margin-right: -20px
}

.table100.ver4 .table100-head {
    padding-right: 20px
}

.table100.ver4 th {
    font-family: Lato-Bold;
    font-size: 18px;
    color: #4272d7;
    line-height: 1.4;
    background-color: transparent;
    border-bottom: 2px solid #f2f2f2
}

.table100.ver4 .column1 {
    padding-left: 7px
}

.table100.ver4 td {
    font-family: Lato-Regular;
    font-size: 15px;
    color: gray;
    line-height: 1.4
}

.table100.ver4 .table100-body tr {
    border-bottom: 1px solid #f2f2f2
}

.table100.ver4 {
    overflow: hidden
}

.table100.ver4 .table100-body {
    padding-right: 20px
}

.table100.ver4 .ps__rail-y {
    right: 0
}

.table100.ver4 .ps__rail-y::before {
    background-color: #ebebeb
}

.table100.ver4 .ps__rail-y .ps__thumb-y::before {
    background-color: #ccc
}

.table100.ver5 {
    margin-right: -30px
}

.table100.ver5 .table100-head {
    padding-right: 30px
}

.table100.ver5 th {
    font-family: Lato-Bold;
    font-size: 14px;
    color: #555;
    line-height: 1.4;
    text-transform: uppercase;
    background-color: transparent
}

.table100.ver5 td {
    font-family: Lato-Regular;
    font-size: 15px;
    color: gray;
    line-height: 1.4;
    background-color: #f7f7f7
}

.table100.ver5 .table100-body tr {
    overflow: hidden;
    border-bottom: 10px solid #fff;
    border-radius: 10px
}

.table100.ver5 .table100-body table {
    border-collapse: separate;
    border-spacing: 0 10px
}

.table100.ver5 .table100-body td {
    border: solid 1px transparent;
    border-style: solid none;
    padding-top: 10px;
    padding-bottom: 10px
}

.table100.ver5 .table100-body td:first-child {
    border-left-style: solid;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px
}

.table100.ver5 .table100-body td:last-child {
    border-right-style: solid;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px
}

.table100.ver5 tr:hover td {
    background-color: #ebebeb;
    cursor: pointer
}

.table100.ver5 .table100-head th {
    padding-top: 25px;
    padding-bottom: 25px
}

.table100.ver5 {
    overflow: hidden
}

.table100.ver5 .table100-body {
    padding-right: 30px
}

.table100.ver5 .ps__rail-y {
    right: 0
}

.table100.ver5 .ps__rail-y::before {
    background-color: #ebebeb
}

.table100.ver5 .ps__rail-y .ps__thumb-y::before {
    background-color: #ccc
}
.MuiButton-containedPrimary {
  color: #fff;
  background-color: #22E4DB !important;
}
